<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        公司简介
      </div>
      <div class="topic_desc_en">
        COMPANY PROFILE
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="article_panel">
      <div class="article_title">
        {{companyBaseInfo.title}}
      </div>
      <div class="article_info">
        <p v-for="item in companyBaseInfo.desc" :key="item">
          {{item}}
        </p>
      </div>
      <div class="features_content">
        <div class="feature_item" v-for="(fItem,idx) in companyFeatures" :key="idx">
          <div class="feature_item_pic">
            <img :src="fItem.icon">
          </div>
          <div class="feature_item_label">
            {{fItem.label}}
          </div>
          <div class="feature_item_desc">
            {{fItem.desc}}
          </div>
        </div>
      </div>
    </div>
    <div class="company_info_content">
      <div class="company_info_main">
        <div class="topic_label">
          实验室分布
        </div>
        <div class="topic_label_en">
          LABORATORY DISTRIBUTION
        </div>
        <div class="topic_line"></div>
        <div class="topic_desc">
          {{companyDetailInfos.desc}}
        </div>
        <div class="company_info_cards">
          <div class="card_item" v-for="(cItem,idx) in companyDetailInfos.infos" :key="idx">
            <div class="card_img">
              <img :src="cItem.pic">
            </div>
            <div class="card_label">
              {{cItem.label}}
            </div>
            <div class="card_value">
              {{cItem.value}}
            </div>
            <div class="card_desc">
              {{cItem.desc}}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="map_content">
      <img :src="companyDetailInfos.pic">
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      // 公司特征
      companyFeatures:[],
      // 公司分布
      
      companyDetailInfos:{},
      // 公司基本信息
      companyBaseInfo:{}
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.getDataCompanyBaseInfo();
      _this.getDataCompanyFeatures();
      _this.getDataCompanyDetailInfos();
    },
    // 获取数据 - 公司信息
    getDataCompanyBaseInfo(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=companyBaseInfo`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.companyBaseInfo = configData;
        }else{
          _this.$notify({
            title: '失败',
            message: "公司信息数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 获取数据 - 公司特点
    getDataCompanyFeatures(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=companyFeatures`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.companyFeatures = configData.map(i=>{
            return Object.assign({},i,{
              icon: `${window.PICSEVERURL}/${i.icon}`
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "公司特点数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 获取数据 - 实验室分布
    getDataCompanyDetailInfos(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=companyDetailInfos`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.companyDetailInfos = Object.assign({},configData,{
            pic:`${window.PICSEVERURL}/${configData.pic}`,
            infos:configData.infos.map(i=>{
              return Object.assign({},i,{
                pic:`${window.PICSEVERURL}/${i.pic}`,
              })
            })
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "实验室分布数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 1rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 1.4rem;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .article_panel {
    width: 100%;
    margin-bottom: 60px;
    .article_title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #000000;
      line-height: 1.4rem;
      margin-bottom: 1.2rem;
    }
    .article_info{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 20px;
      p{
        margin-bottom: 1.2rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .features_content{
      display: flex;
      flex-wrap: wrap;
      .feature_item{
        min-width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        .feature_item_pic{
          margin-bottom: 0.8rem;
          text-align: center;
          img{
            width: 60%;
          }
        }
        .feature_item_label{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 1.0rem;
          color: #000000;
          line-height: 1.2rem;
          margin-bottom: 10px;
        }
        .feature_item_desc{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.8rem;
          color: #333333;
          line-height: 0.8rem;
        }
      }
    }
  }
  .company_info_content{
    width: 100%;
    background: #0086D1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.8rem;
    .company_info_main{
      width: 100%;
      position: relative;
      padding: 1rem 0.5rem;
      box-sizing: border-box;
      .topic_label{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.5rem;
        color: #FFFFFF;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
      }
      .topic_label_en{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #FFFFFF;
        line-height: 1.4rem;
        margin-bottom: 1rem;
      }
      .topic_line{
        width: 76px;
        height: 3px;
        background: #FFFFFF;
        margin-bottom: 30px;
      }
      .topic_desc{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #FFFFFF;
        line-height: 1.4rem;
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .company_info_cards{
        // position: absolute;
        // top: 375px;
        width: 100%;
        // display: flex;
        .card_item{
          // flex: 1;
          padding: 0 0 1rem 0;
          background: #FFFFFF;
          box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
          margin-bottom: 1rem;
          .card_img{
            width: 100%;
            height: 206px;
            margin-bottom: 0.8rem;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .card_label{
            margin: 0 1.2rem;
            width: calc(100% - 46px);
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.2rem;
            color: #333333;
            line-height: 1.4rem;
            margin-bottom: 7px;
          }
          .card_value{
            margin: 0 23px;
            width: calc(100% - 46px);
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 1rem;
            color: #0086D1;
            line-height: 1.2rem;
            margin-bottom: 0.8rem;
          }
          .card_desc{
            margin: 0 1rem;
            width: calc(100% - 46px);
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.8rem;
            color: #333333;
            line-height: 1.4rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
  .map_content{
    width: 100%;
    img{
      width: 100%;
    }
  }
}
</style>